import React, { useState } from "react";
import axios from "axios";
import anime from "animejs/lib/anime.es.js";

import AppearingMoon from "../components/AppearingMoon.js";

import "../scss/App.scss";

function GincanaX() {
  const [Severidade, setSeveridade] = useState("");
  const [Componente, setComponente] = useState("");
  const [VersaoApp, setVersaoApp] = useState("");
  const [DescricaoProblema, setDescricaoProblema] = useState("");
  const [Email, setEmail] = useState("");
  const [Telefone, setTelefone] = useState("");
  const [Arquivos, setArquivos] = useState([]);

  const [loading, setLoading] = useState(false);
  const [sendResult, setSendResult] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    setLoading(true);
    let animation = anime({
      targets: "#lua",
      rotate: "-360deg",
      loop: true,
      duration: 1700,
      easing: "easeInOutElastic(1, .5)",
    });

    const formData = new FormData();

    formData.set("Severidade", Severidade);
    formData.set("Componente", Componente);
    formData.set("VersaoApp", VersaoApp);
    formData.set("DescricaoProblema", DescricaoProblema);
    formData.set("Email", Email);
    formData.set("Telefone", Telefone);
    Array.from(Arquivos).forEach((file) => formData.append("Files", file));

    axios
      .post(
        "https://httptriggercreategxsupportticket.azurewebsites.net/api/httptriggercreategxsupportticket?code=TEy8aysopx53W5BEu9mtgRaVL-rWtLeKwS_E48EIbuc-AzFuXWBtQA==",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(function (response) {
        animation.restart();
        animation.pause();
        setLoading(false);
        setSendResult(response.data);
      })
      .catch(function (error) {
        animation.restart();
        animation.pause();
        setLoading(false);
        setSendResult(error.response.data);
      });
  };

  return (
    <main className="gincanax">
      <header>
        <div />
        <h1>Abertura de chamados de Suporte da Gincana da Jornada X</h1>
      </header>
      <section>
        <h3>
          Esse formulário é para abertura de chamados de Suporte para a Gincana
          da Jornada X.
        </h3>

        <p>Versão deste formulário: 15/ago/2022 15h00.</p>

        <form onSubmit={sendEmail}>
          <label>
            <span>
              1. Qual a severidade deste Atendimento? <b>*</b>
            </span>
            <div>
              <input
                type="radio"
                id="Severidade1"
                value="Estou com uma dúvida ou sugestão."
                checked={Severidade === "Estou com uma dúvida ou sugestão."}
                onChange={(e) => setSeveridade(e.target.value)}
              />
              <label htmlFor="Severidade1">
                Estou com uma dúvida ou sugestão.
              </label>
              <input
                type="radio"
                id="Severidade2"
                value="Um ou mais componentes (App móvel, Dashboard ou site da Escola) da plataforma estão parados nesse momento."
                checked={
                  Severidade ===
                  "Um ou mais componentes (App móvel, Dashboard ou site da Escola) da plataforma estão parados nesse momento."
                }
                onChange={(e) => setSeveridade(e.target.value)}
              />
              <label htmlFor="Severidade2">
                Um ou mais componentes (App móvel, Dashboard ou site da Escola)
                da plataforma estão <strong>parados</strong> nesse momento.
              </label>
              <input
                type="radio"
                id="Severidade3"
                value="Um ou mais componentes (App móvel, Dashboard ou site da Escola) da plataforma estão com acesso instável, em alguns momentos funcionam e em outros não conectam."
                checked={
                  Severidade ===
                  "Um ou mais componentes (App móvel, Dashboard ou site da Escola) da plataforma estão com acesso instável, em alguns momentos funcionam e em outros não conectam."
                }
                onChange={(e) => setSeveridade(e.target.value)}
              />
              <label htmlFor="Severidade3">
                Um ou mais componentes (App móvel, Dashboard ou site da Escola)
                da plataforma estão com acesso <strong>instável</strong>, em
                alguns momentos <strong>funcionam</strong> e em outros{" "}
                <strong>não conectam</strong>.
              </label>
              <input
                type="radio"
                id="Severidade4"
                value="Esse chamado é específico para o ambiente de nuvem GCP (Google Cloud Platform) que está apresentando instabilidade ou falha."
                checked={
                  Severidade ===
                  "Esse chamado é específico para o ambiente de nuvem GCP (Google Cloud Platform) que está apresentando instabilidade ou falha."
                }
                onChange={(e) => setSeveridade(e.target.value)}
              />
              <label htmlFor="Severidade4">
                Esse chamado é específico para o ambiente de nuvem{" "}
                <strong>GCP</strong> (Google Cloud Platform) que está
                apresentando <strong>instabilidade</strong> ou falha.
              </label>
              <input
                type="radio"
                id="Severidade5"
                value="Esse chamado é específico para o ambiente de nuvem GCP (Google Cloud Platform) que está offline."
                checked={
                  Severidade ===
                  "Esse chamado é específico para o ambiente de nuvem GCP (Google Cloud Platform) que está offline."
                }
                onChange={(e) => setSeveridade(e.target.value)}
              />{" "}
              <label htmlFor="Severidade5">
                Esse chamado é específico para o ambiente de nuvem{" "}
                <strong>GCP</strong> (Google Cloud Platform) que está{" "}
                <strong>offline</strong>.
              </label>
            </div>
          </label>
          <label>
            <span>
              2. Para qual componente você quer abrir esse chamado? <b>*</b>
            </span>
            <div>
              <input
                type="radio"
                id="Componente1"
                value="Aplicativo móvel (Android e iOS)."
                checked={Componente === "Aplicativo móvel (Android e iOS)."}
                onChange={(e) => setComponente(e.target.value)}
              />
              <label htmlFor="Componente1">
                Aplicativo móvel (Android e iOS).
              </label>{" "}
              <input
                type="radio"
                id="Componente2"
                value="Dashboard."
                checked={Componente === "Dashboard."}
                onChange={(e) => setComponente(e.target.value)}
              />
              <label htmlFor="Componente2">Dashboard.</label>{" "}
              <input
                type="radio"
                id="Componente3"
                value="Site da Escola."
                checked={Componente === "Site da Escola."}
                onChange={(e) => setComponente(e.target.value)}
              />
              <label htmlFor="Componente3">Site da Escola.</label>{" "}
              <input
                type="radio"
                id="Componente4"
                value="Ambiente de nuvem GCP (Google Cloud Platform)."
                checked={
                  Componente ===
                  "Ambiente de nuvem GCP (Google Cloud Platform)."
                }
                onChange={(e) => setComponente(e.target.value)}
              />
              <label htmlFor="Componente4">
                Ambiente de nuvem GCP (Google Cloud Platform).
              </label>
            </div>
          </label>
          <label>
            <span>
              3. É muito importante que a versão do Aplicativo esteja
              atualizada. Em caso de dúvidas vá até a loja online (Google Play
              ou Apple AppStore) e verifique a versão. <br />
              <br /> Qual a versão do Aplicativo móvel? A versão pode ser
              encontrada na barra de menu lateral do Aplicativo.
              <br />
              <br />
              Caso a versão do Aplicativo não esteja disponível nas opções,
              digite a data da versão na opção Outra. <b>*</b>
            </span>
            <div>
              <input
                type="radio"
                id="VersaoApp1"
                value="Esse chamado não é específico para o Aplicativo móvel e sim para outro componente da solução."
                checked={
                  VersaoApp ===
                  "Esse chamado não é específico para o Aplicativo móvel e sim para outro componente da solução."
                }
                onChange={(e) => setVersaoApp(e.target.value)}
              />
              <label htmlFor="VersaoApp1">
                Esse chamado não é específico para o Aplicativo móvel e sim para
                outro componente da solução.
              </label>{" "}
              <input
                type="radio"
                id="VersaoApp2"
                value="Versão 07/07/2022."
                checked={VersaoApp === "Versão 07/07/2022."}
                onChange={(e) => setVersaoApp(e.target.value)}
              />
              <label htmlFor="VersaoApp2">Versão 07/07/2022.</label>{" "}
              <label htmlFor="VersaoApp3">Outra:</label>
              <input
                type="text"
                placeholder="Digite a versão"
                id="VersaoApp3"
                onChange={(e) => setVersaoApp(e.target.value)}
              />
            </div>
          </label>
          <label htmlFor="message">
            <span htmlFor="message">
              4. Descreva o problema da forma mais completa possível. <b>*</b>
            </span>
            <textarea
              type="text"
              placeholder="Digite sua mensagem"
              id="message"
              value={DescricaoProblema}
              required
              onChange={(e) => setDescricaoProblema(e.target.value)}
              className={DescricaoProblema ? "filled" : ""}
            />
          </label>
          <label htmlFor="Arquivos">
            <span htmlFor="Arquivos">
              5. Por favor envie telas ou imagens que ajude a descrever o
              problema.
            </span>
            <input
              type="file"
              accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
              multiple={true}
              id="Arquivos"
              // value={Arquivos}
              onChange={(e) => setArquivos(e.target.files)}
              className={Array.from(Arquivos).length > 0 ? "filled" : ""}
            />
          </label>
          <label htmlFor="Email">
            <span htmlFor="Email">
              6. Qual o seu e-mail? <b>*</b>
            </span>
            <input
              type="email"
              placeholder="Digite seu E-mail"
              id="Email"
              value={Email}
              required
              onChange={(e) => setEmail(e.target.value)}
              className={Email ? "filled" : ""}
            />
          </label>
          <label htmlFor="Telefone">
            <span htmlFor="Telefone">
              7. Qual o seu telefone ou WhatsApp? <b>*</b>
            </span>
            <input
              type="text"
              placeholder="Digite seu telefone"
              id="Telefone"
              value={Telefone}
              required
              onChange={(e) =>
                setTelefone(
                  e.target.value
                    .toString()
                    .replace(/\D/g, "")
                    .replace(/(\d{2})(\d)/, "($1) $2")
                    .replace(/(\d{5})(\d{1,2})/, "$1-$2")
                    .replace(/(-\d{4})\d+?$/, "$1")
                )
              }
              className={Telefone ? "filled" : ""}
            />
          </label>

          <div className="send-div">
            <p className="send-message">{sendResult ?? ""}</p>
            <button type="submit" disabled={loading}>
              <AppearingMoon />
              {loading ? "ENVIANDO" : "ENVIAR"}
            </button>
          </div>
        </form>
      </section>
    </main>
  );
}

export default GincanaX;
